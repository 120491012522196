<template>
    <modal ref="modalRegistrarPago" titulo="Registrar pago de administración" :cargando="cargando" :adicional="`${statusCheck ? 'Registrar' : ''}`" @adicional="registrarPago">
        <div v-if="statusCheck">
            <div class="col-12 text-center">
                <p>Selecciona el mes hasta el cual va a quedar paga la administración.</p>
            </div>
            <div class="container overflow-auto custom-scroll" style="height:calc(80vh - 400px)">
                <div v-for="(mes,index) in meses" :key="index" class="row mx-2 my-3">
                    <div class="col-auto">
                        <el-checkbox v-model="mes.checked" class="el-checkbox-negro" :true-label="1" :false-label="0" @change="seleccionarFecha(index)">
                            <span class="f-18">{{ _.startCase(formatearFecha(mes.fecha_pago_mes,'MMMM YYYY')) }}</span>
                        </el-checkbox>
                    </div>
                    <div class="col d-middle justify-content-end">
                        <span :class="`f-18 ${mes.vencido ? 'text-orange f-500 mr-2' : 'f-500'} `">{{ convertMoney(mes.valor) }}</span>
                        <i v-show="mes.vencido" class="text-orange icon-alerta_2 f-18" />
                    </div>
                </div>
            </div>
            <div class="col-12">
                <span class="label-inputs f-14 mx-2">Comentario</span>
                <el-input v-model="comentario" type="textarea" placeholder="Déjale un comentario" maxlength="300" show-word-limit rows="4" />
            </div>
            <!-- queda pendiente validar la subida de solo una imagen -->
            <div class="col-12 mt-2">
                <el-upload
                action="#"
                list-type="picture-card"
                :on-change="saveImage"
                :auto-upload="false"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
                :multiple="false"
                >
                    <i slot="default" class="el-icon-plus" />
                    <div slot="file" slot-scope="{file}">
                        <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url" alt=""
                        />
                        <span class="el-upload-list__item-actions">
                            <span
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                            >
                                <i class="el-icon-delete" />
                            </span>
                        </span>
                    </div>
                </el-upload>
            </div>
        </div>
        <div v-else>
            <p class="text-center">{{ mensaje }}</p>
        </div>
    </modal>
</template>
<script>
import Viviendas from '~/services/viviendas'
export default {
    data(){
        return{
            cargando: false,
            fileList: [],
            file: {
                raw: null
            },
            imageUrl: null,
            mensaje: null,
            comentario:'',
            meses: [],
            statusCheck: false
        }
    },
    methods:{
        toggle(){
            this.fileList = []
            this.comentario = null
            this.getListadoMesesAPagar()
            this.$refs.modalRegistrarPago.toggle()
        },
        handleRemove(){
            this.fileList = []
            this.file = {}
        },
        handleExceed(){
            this.notificacion('','Solo puedes subir una imagen','warning')
        },
        saveImage(file,fileList){
            this.file = file
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        seleccionarFecha(index){
            this.meses.forEach((el,indexEach)=>{
                if(index >= indexEach){
                    if (index == indexEach){
                        if (this.meses[indexEach].checked != 1){
                            this.meses[indexEach].checked = 0
                        }
                    }else{
                        this.meses[indexEach].checked = 1
                    }
                } else{
                    this.meses[indexEach].checked = 0
                }
            })
        },
        async getListadoMesesAPagar(){
            try {
                const { data } = await Viviendas.listarMesesDisponiblesAPagar(this.$route.params.id_vivienda)
                this.meses = data.data.map(el=>{el.checked = 0;return el})

                this.statusCheck = data.success
                this.mensaje = data.mensaje
            } catch (error){
                this.errorCatch(error)
            }
        },
        async registrarPago(){
            try {

                
                let mesesSomeChecked = this.meses.some(el=>el.checked == 1)

                if(!mesesSomeChecked) return this.notificacion('','Por favor selecciona al menos un mes a pagar')

                this.cargando = true
                
                let mesesArray = this.meses.filter(el=>el.checked == 1).map(el=>{
                    let elnuevo = {};
                    elnuevo.mes = el.fecha_pago_mes
                    elnuevo.valor = el.valor
                    return elnuevo
                })

                let formData = new FormData();

                formData.append('id_vivienda',this.$route.params.id_vivienda);
                if(this.comentario) formData.append('comentario',this.comentario);
                mesesArray.forEach(el=>formData.append('meses[]',JSON.stringify(el)))
                if(this.file.raw) formData.append('imagen',this.file.raw);

                const { data } = await Viviendas.registarPago(formData)
                
                this.$emit('actualizar')
                this.notificacion('','Pago registrado correctamente','success')
                
                this.$refs.modalRegistrarPago.toggle()

            } catch (error){

                Object.values(error.response.data.data).flat().forEach(el=>{
                    setTimeout(() => {
                        this.notificacion('',el,'warning')
                    }, 200);
                })
                this.errorCatch(error)
            } finally{
                this.cargando = false
            }

        }
    }
}
</script>